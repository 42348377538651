<template>
    <v-content class="movie">

    <SelectedShop v-if="init.branch_flg == 1" :selected_shop_name="selected_shop" :router_param="{ name: 'movie_shops', query: { feature_id: 'movie'}}" ></SelectedShop>

        <v-row no-gutters v-if="items">
            <v-col cols="12" class="mb-12" v-for="item in items" :key="item.id">
                <div @click="toHref(item.movie_path)">
                  <v-img :src="`//img.youtube.com/vi/${movie_id(item.movie_path)}/hqdefault.jpg`" width="100%" alt="ムービー">
                    <v-icon x-large class="playicon">fab fa-youtube</v-icon>
                  </v-img>
                </div>
                <p class="subtitle-2 px-3">{{item.movie_comment}}</p>
            </v-col>
        </v-row>

        <p v-if="!items" class="body-1 text-center pa-5">{{nodata}}</p>
    </v-content>
</template>

<script>
import SelectedShop from '@/components/selected_shop.vue'
export default {
  components: {
    SelectedShop,
  },
  data() {
    return {
      init: [],
      page: 1,
      items: null,
      nodata: "",
    };
  },
  methods: {
    movie_id(url) {
      var youtube_video_id = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
      return youtube_video_id
    },
    toHref(url) {
      var req = {
        destination: url,
        transition_type: 4,
      }
      if (!(this.screenTransition(req))) {
        this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
      }
    },
  },
  async created() {
    this.getUrlParam()
    this.init = this.storageGet('*')
    this.selected_shop = this.init.selected_shop
    var shop_id = this.getUrlGetParam('shop_id');

    if (!shop_id) {
      shop_id = this.init.sid
    }

    var params = {
      'shop_id': shop_id,
    }

    var req = { params: params }

    // ムービー一覧API
    var res = await this.apiCall('/movie/list', req)
    if (!res.data) {
      this.nodata = "登録されたムービーはありません。"
    }
    this.$set(this, 'items', res.data);
  },
};
</script>

<style scoped>
.movie a {
    text-decoration: none;
    display: block;
    position: relative;
}

.playicon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    font-size: 100px !important;
}
</style>
